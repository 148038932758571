import React from 'react';

import styled from 'styled-components';

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    border-radius: 0px;
    cursor: default;
    color: #003;
    font-size: 10px;
    border: none;
    font-family: Arial, sans-serif;

    h3 {
        color: #666;
        font-size: 13px;
        align-self: flex-end;
        font-family: Arial;
    }
    h4 {
        color: #666;
        font-size: 12px;
        font-weight: 600;
        align-self: flex-end;
        font-family: Arial;
    }
    h5 {
        color: #666;
        font-size: 10px;
        font-weight: 600;
        align-self: flex-end;
        font-family: Arial;
    }

    &.is-active {
        background: #ddd;
    }

    :hover {
        background: #ddd;
        cursor: pointer;
    }

    :focus {
        box-shadow: none !important;
    }

    :first-child {
        border-radius: 2px 0px 0px 2px;
    }

    :last-child {
        border-radius: 0px 2px 2px 0px;
    }

    :not(:last-child) {
        border-right: 1px solid #ddd;
    }
`;

interface MarkupButtonProps {
    children: React.ReactNode;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
    className?: string;
}

const MarkupButton = ({ children, onClick, className }: MarkupButtonProps) => {
    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick(e);
    };

    return (
        <Button className={className} onClick={onClickHandler}>
            {children}
        </Button>
    );
};

export default MarkupButton;
