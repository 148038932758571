import * as yup from 'yup';

import { minMoneyRequiredOf } from './number';
import { stringRequired } from './string';

import type { I18n } from '@lingui/core';
import type { AssetInstitution, AssetTypes } from 'types/asset';

export const getCreateAssetSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: stringRequired(i18n),
        })
        .concat(getAssetSchema(i18n));

export const getAssetSchema = (i18n: I18n) =>
    yup.object().shape({
        type: stringRequired(i18n),
        totalValue: minMoneyRequiredOf(i18n).emptyAsUndefined(),
        amountUsedForDownPayment: yup
            .object()
            .when(['totalValue', 'type'], (totalValue, type) => {
                if (type === 'VEHICLE') {
                    return yup
                        .number()
                        .optional()
                        .nullable()
                        .emptyAsUndefined();
                }
                return minMoneyRequiredOf(i18n)
                    .max(totalValue, i18n._({ id: 'amountUsedForDownPayment' }))
                    .emptyAsUndefined();
            }),

        // type -> GIFT
        donorFirstName: yup.string().when('type', {
            is: (type: AssetTypes) => type === 'GIFT',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),
        donorLastName: yup.string().when('type', {
            is: (type: AssetTypes) => type === 'GIFT',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),
        familyRelationship: yup.string().when('type', {
            is: (type: AssetTypes) => type === 'GIFT',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),

        // type -> NON_REGISTERED_INVESTMENTS/RRSP/TFSA/SAVINGS
        institution: yup.string().when('type', {
            is: (type: AssetTypes) =>
                [
                    'NON_REGISTERED_INVESTMENTS',
                    'RRSP',
                    'SAVINGS',
                    'TFSA',
                    'HELOC',
                ].includes(type),
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),
        institutionOther: yup
            .string()
            .nullable()
            .when('institution', {
                is: (institution: AssetInstitution) => institution === 'OTHER',
                then: (schema) =>
                    schema.required(i18n._({ id: 'error.fieldRequired' })),
                otherwise: (schema) => schema.optional(),
            }),

        // type -> OTHER
        name: yup.string().when('type', {
            is: (type: AssetTypes) => type === 'OTHER',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),

        // type -> OTHER/VEHICLE
        description: yup.string().when('type', {
            is: (type: AssetTypes) => ['OTHER', 'VEHICLE'].includes(type),
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),

        // type -> PROPERTY
        existingPropertyId: yup.number().when('type', {
            is: (type: AssetTypes) => type === 'PROPERTY',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),

        // type -> VEHICLE
        make: yup.string().when('type', {
            is: (type: AssetTypes) => type === 'VEHICLE',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),
        model: yup.string().when('type', {
            is: (type: AssetTypes) => type === 'VEHICLE',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),
        year: yup
            .number()
            .isValueNaN()
            .nullable()
            .when('type', {
                is: (type: AssetTypes) => type === 'VEHICLE',
                then: (schema) =>
                    schema.required(i18n._({ id: 'error.fieldRequired' })),
                otherwise: (schema) => schema.optional(),
            }),
    });
