import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { DateWithPopper } from 'components/popper/date-popper';
import {
    INTEREST_RATE_TYPE,
    MORTGAGE_TYPE,
    LENDER_SELECT_OPTIONS,
    TERM_TYPE,
    OWNED_PROPERTY_PAYMENT_FREQUENCY_OPTIONS,
} from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { selectedApplication } from 'store/applications';
import { OtherProperty } from 'types/property';
import { formatMoney, maxTwoDecimal } from 'utils';
import { useIsTenant } from 'utils/useIsTenant';
import { compareForId } from 'utils/validations/comparators';

import styles from './property-detail-section.module.scss';

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    isBridgeLoanRequired: boolean;
    hasMortgage: string | boolean;
    showMortgageDetails: boolean;
    property: OtherProperty;
};

export const MortgageInformation = ({
    isCreating,
    isEditing,
    isBridgeLoanRequired,
    hasMortgage,
    showMortgageDetails,
    property,
}: Props) => {
    const { portProperty, type } = useRecoilValue(selectedApplication);

    const { i18n } = useI18n();
    const { partialSavingOwnedProperties } = useFlags();

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    const {
        balance,
        existingLoanNumber,
        interestRate,
        interestRateType,
        lender,
        maturityDate,
        mortgageType,
        payment,
        termType,
    } = property.mortgage || {};

    return (
        <Flex
            className={styles['property-detail-section']}
            direction="column"
            gap={4}
        >
            <Typography size={0} weight={6} textColor="var(--color-black)">
                {i18n._('mortgageInformation')}
            </Typography>
            <Grid
                className={cn(styles['grid'], {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <Typography size={0} textColor="var(--color-black)">
                    {i18n._('doYouStillHaveMortgage')}
                </Typography>
                <EditableCell
                    required
                    name="hasMortgage"
                    label="mortgageLabel"
                    fieldType="boolean"
                    value={hasMortgage}
                    isEditing={isEditing}
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />
            </Grid>
            {showMortgageDetails && (
                <Grid className={styles['grid']} gap={4}>
                    <EditableCell
                        required={
                            !partialSavingOwnedProperties ||
                            isBridgeLoanRequired
                        }
                        name="mortgage.balance"
                        label="mortgageBalance"
                        fieldType="money"
                        value={formatMoney(balance)}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        required
                        name="mortgage.payment.amount"
                        label="mortgagePayment"
                        fieldType="money"
                        value={formatMoney(payment?.amount)}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        required
                        name="mortgage.payment.frequency"
                        label="frequency"
                        fieldType="select"
                        options={OWNED_PROPERTY_PAYMENT_FREQUENCY_OPTIONS(i18n)}
                        value={payment?.frequency}
                        isClearable
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        required={!partialSavingOwnedProperties}
                        name="mortgage.interestRate"
                        label="interestRate"
                        fieldType="number"
                        max={100}
                        value={maxTwoDecimal(interestRate)}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        required={!partialSavingOwnedProperties}
                        name="mortgage.interestRateType"
                        label="interestRateType"
                        fieldType="select"
                        options={INTEREST_RATE_TYPE(i18n)}
                        value={interestRateType}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <DateWithPopper
                        required={!partialSavingOwnedProperties}
                        name="mortgage.maturityDate"
                        label="mortgageTermDueDate"
                        value={maturityDate || ''}
                        isEditing={isEditing}
                        placement="left"
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        required={!partialSavingOwnedProperties}
                        name="mortgage.mortgageType"
                        label="mortgageType"
                        fieldType="select"
                        options={MORTGAGE_TYPE(i18n)}
                        value={mortgageType}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        required={!partialSavingOwnedProperties}
                        name="mortgage.lender"
                        label="lender"
                        fieldType="select"
                        options={LENDER_SELECT_OPTIONS(i18n)}
                        value={lender}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        required={!partialSavingOwnedProperties}
                        name="mortgage.termType"
                        label="termType"
                        fieldType="select"
                        options={TERM_TYPE(i18n)}
                        value={termType}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    <EditableCell
                        name="mortgage.existingLoanNumber"
                        label="existingLoanNumber"
                        fieldType="simpleNumber"
                        value={existingLoanNumber}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                    {type === 'PORT' && isIGTenant && (
                        <EditableCell
                            required
                            name="mortgage.portIndicator"
                            label="portIndicator"
                            fieldType="boolean"
                            value={portProperty?.id === property?.id}
                            isEditing={isEditing}
                            problemType={ProblemType.properties}
                        />
                    )}
                </Grid>
            )}
        </Flex>
    );
};
