import React, { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Flex } from '@nestoca/ui';
import css from '@styled-system/css';
import { Edit } from 'grommet-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ConfirmDeleteText } from 'components/confirm/confirm-delete-text';
import { useConfirm } from 'components/confirm/use-confirm';
import { EditableGrid } from 'components/editable';
import { Titles } from 'components/titles/titles';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getUseProblemsValidation,
    useRefreshApplicationById,
    getApplication,
    useRefreshApplicationMortgage,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import { isSyncFormEditState } from 'store/middle-office-sync';
import { useRefreshQualification } from 'store/qualification';
import { useEditingRights } from 'store/rights';
import { useRefreshSubmissionNotes } from 'store/submission-notes';
import { includedInLoadingState } from 'store/ui';
import { Application } from 'types/application';
import {
    booleanNormalizer,
    compose,
    isMiddleOfficeItem,
    maxTwoDecimal,
    moneyNormalizer,
    numberNormalizer,
} from 'utils';
import { formatAddress } from 'utils/address';
import { useEditingContext } from 'utils/use-editing-context';
import { useIsTenant } from 'utils/useIsTenant';
import { getOtherPropertySchema } from 'validations/other-property';
import { getOtherPropertyPartialSchema } from 'validations/other-property-partial';

import { Sections } from './sections';

import type { Applicant as ApplicantType } from 'types/applicant';
import type { AmountFrequency } from 'types/index';
import type { OtherProperty, PropertyIncludedFieldName } from 'types/property';

type MortageValues = OtherProperty['mortgage'] & {
    portIndicator?: boolean;
};

export type FormValues = OtherProperty & {
    mortgage: MortageValues;
    bridgeLoanNumber?: string;
    bridgeLoanRequired?: boolean;
};

type PropertyProps = {
    property: OtherProperty;
    editMode?: boolean;
    applicationId: number;
    readonly?: boolean;
    applicant: ApplicantType;
    noBorders?: boolean;
    onSaveSuccess?: (property: OtherProperty) => void;
    onDeleteSuccess?: () => void;
    editableKey?: string;
    isServicingSync?: boolean;
};

export const normalizeProperty = R.evolve({
    purchasePrice: moneyNormalizer,
    estimatedPropertyValue: moneyNormalizer,
    taxes: {
        amount: moneyNormalizer,
        year: (value) => numberNormalizer(value) || null,
    },
    rentalIncome: { amount: moneyNormalizer },
    mortgage: {
        balance: (value) => moneyNormalizer(value) || null,
        payment: {
            amount: moneyNormalizer,
            frequency: (value) => value || '',
        },
        interestRate: (value: string | number) =>
            numberNormalizer(maxTwoDecimal(value)) || null,
        //
        interestRateType: (value) => value || null,
        lender: (value) => value || null,
        maturityDate: (value) => value || null,
    },
    condoFees: {
        amount: (value) => moneyNormalizer(value) || 0,
        heatingIncluded: booleanNormalizer,
    },
    heatingCost: {
        amount: moneyNormalizer,
    },
    coOwnerApplicantIds: (value) => value || [],
    hasMortgage: booleanNormalizer,
    bridgeLoanAmount: (value: number) => moneyNormalizer(value) || 0,
    bridgeLoanRate: (value: number) =>
        numberNormalizer(maxTwoDecimal(value)) || null,
});

export const getOwnedPropertyFields = ({
    incomingValues,
    property,
}: {
    incomingValues: FormValues;
    property: OtherProperty;
}) => {
    const isCondo = incomingValues?.type
        ? incomingValues?.type === 'CONDO'
        : property.type === 'CONDO';

    const data: FormValues = compose(
        normalizeProperty,
        cleanOwnedPropertyData
    )({
        ...property,
        ...incomingValues,
        mortgage: booleanNormalizer(incomingValues.hasMortgage)
            ? incomingValues.mortgage
            : booleanNormalizer(incomingValues.bridgeLoanRequired)
              ? { balance: incomingValues.mortgage.balance }
              : {},
        heatingCost: incomingValues.condoFees?.heatingIncluded
            ? ({ amount: 0, frequency: 'ANNUALLY' } as AmountFrequency)
            : incomingValues.heatingCost,
    });

    const propertyData = {
        ...data,
        condoFees: isCondo
            ? data.condoFees
            : {
                  heatingIncluded: false,
              },
    };

    return propertyData;
};

export function cleanOwnedPropertyData(formValues: FormValues) {
    const isSold = R.propEq('SOLD', 'afterTransactionPurpose');

    /* Fields bridgeLoanRequired and bridgeLoanNumber are only part
    of the formValues if it's a bridge loan. If not they are unregister. */
    const bridgeLoanRequired = booleanNormalizer(formValues.bridgeLoanRequired);

    const bridgeLoanOmittedKeys = [
        'estimatedPropertyValue',
        'type',
        'taxes',
        'rentalIncome',
        'condoFees',
        'bridgeLoanRequired',
        'bridgeLoanNumber',
    ];

    if (bridgeLoanRequired) {
        return R.omit(bridgeLoanOmittedKeys, formValues);
    }

    const soldOmittedKeys = [
        'estimatedPropertyValue',
        'type',
        'taxes',
        'rentalIncome',
        'condoFees',
        'bridgeLoanRequired',
        'bridgeLoanNumber',
        'bridgeLoanAmount',
        'bridgeLoanRate',
    ];

    const notSoldOmittedKeys = [
        'currentSaleStatus',
        'purchasePrice',
        'sellingDate',
        'bridgeLoanAmount',
        'bridgeLoanRate',
    ];

    const sold = R.omit(soldOmittedKeys);

    const isRental = R.propSatisfies(
        (afterTransactionPurpose) =>
            afterTransactionPurpose === 'OWNER_OCCUPIED_AND_RENTAL' ||
            afterTransactionPurpose === 'RENTAL',
        'afterTransactionPurpose'
    );

    // const isCondo = R.propSatisfies((type) => type === 'CONDO', 'type');

    const notSold = R.pipe(
        // If not sold alway remove these properties
        R.omit(notSoldOmittedKeys),
        // if NOT rental remove rentalIncome from payload
        // eslint-disable-next-line
        // @ts-ignore
        R.ifElse(isRental, (data) => data, R.omit(['rentalIncome']))
        // if NOT condo remove heatingIncluded from payload
        // R.ifElse(isCondo, (data) => data, R.omit(['condoFees.heatingIncluded']))
    );

    return R.ifElse(isSold, sold, notSold)(formValues);
}

export const onChangePortIndicator = async (
    value: boolean,
    application: Application,
    property: OtherProperty
) => {
    const normalizedValue = booleanNormalizer(value);

    if (normalizedValue && application.portProperty?.id === property.id) return;

    if (!normalizedValue && application.portProperty?.id !== property.id)
        return;

    try {
        application.portProperty &&
            (await apiClient.deletePortProperties(
                application.id,
                property.applicantId
            )),
            normalizedValue &&
                (await apiClient.setPortProperty(
                    application.id,
                    property.applicantId,
                    property.id
                ));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.info(` Change Port indicator error: ${e}`);
    }
};

export const OwnedProperty = ({
    property,
    applicationId,
    readonly = false,
    applicant,
    noBorders,
    onDeleteSuccess,
    onSaveSuccess,
    editableKey: customEditableKey,
    isServicingSync = false,
}: PropertyProps) => {
    const { i18n } = useI18n();
    const { addToast } = useToasts();
    const { open: openConfirm } = useConfirm();
    const refreshApplication = useRefreshApplicationById(applicationId);
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);
    const refreshApplicationMortgage =
        useRefreshApplicationMortgage(applicationId);
    const usePartialValidation = useRecoilValue(getUseProblemsValidation);
    const setIncludedInLoadingState = useSetRecoilState(includedInLoadingState);
    const loadingState = useRecoilValue(includedInLoadingState);
    const hasEditingRights = useEditingRights();
    const application = useRecoilValue(getApplication(applicationId));
    const { editingKey, setEditingKey, clearEditingKey } = useEditingContext();

    const { partialSavingOwnedProperties } = useFlags();

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    const editableKey = customEditableKey || `ownedProperty-${property.id}`;

    const isEditing = useMemo(
        () => !readonly && editingKey === editableKey,
        [editingKey]
    );

    const setSyncFormEditState = useSetRecoilState(isSyncFormEditState);

    useEffect(() => {
        if (isServicingSync) {
            setSyncFormEditState(isEditing);
        }
    }, [isEditing, isServicingSync]);

    const bridgeLoanRequired =
        property.bridgeLoanAmount || property.bridgeLoanRate ? true : false;
    const bridgeLoanNumber = application?.bridgeLoanNumber;

    const defaultValues = {
        ...property,
        mortgage: {
            ...property.mortgage,
            portIndicator: application.portProperty?.id === property.id,
        },
        bridgeLoanRequired,
        bridgeLoanNumber,
    };

    const schema =
        partialSavingOwnedProperties && usePartialValidation
            ? getOtherPropertyPartialSchema(i18n)
            : getOtherPropertySchema(i18n);

    const onSubmit = async (values: FormValues) => {
        try {
            const params = getOwnedPropertyFields({
                incomingValues: values,
                property,
            });

            application.type === 'PORT' &&
                (await onChangePortIndicator(
                    params.mortgage.portIndicator,
                    application,
                    property
                ));

            if (isMiddleOfficeItem(property)) {
                await apiClient.updateOtherProperties(
                    applicationId,
                    property.id,
                    property.applicantId,
                    params
                );

                onSaveSuccess && onSaveSuccess(params);
            } else {
                const { data } = await apiClient.createOtherProperty(
                    applicationId,
                    applicant.applicantId,
                    params
                );

                onSaveSuccess && onSaveSuccess(data);
            }

            await refreshApplication();
            await refreshApplicationDocumentsCounts();
            await refreshSubmissionNotes();
            await refreshApplicationMortgage();

            addToast(i18n._('successfullySaved'), {
                appearance: 'success',
            });
            await refreshQualification(applicationId);
            setIncludedInLoadingState([]);
            clearEditingKey();
        } catch (error) {
            setIncludedInLoadingState([]);
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
        }
    };

    const onToggle = (
        included: boolean,
        fieldName: PropertyIncludedFieldName
    ) => {
        setIncludedInLoadingState([...loadingState, fieldName]);
        onSubmit({
            ...property,
            bridgeLoanNumber,
            bridgeLoanRequired,
            [fieldName]: included,
            hasMortgage:
                !!property.mortgage?.balance || !!property.mortgage?.payment,
        });
    };

    const handleConfirmDestroy = async () => {
        try {
            if (isMiddleOfficeItem(property)) {
                await apiClient.deleteOtherProperty(
                    applicationId,
                    property.id,
                    property.applicantId
                );

                await refreshApplication();
                await refreshApplicationDocumentsCounts();
                await refreshQualification(applicationId);
            }

            onDeleteSuccess && onDeleteSuccess();
        } catch (error) {
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
        } finally {
            clearEditingKey();
        }
    };

    const onDestroy = () => {
        openConfirm({
            onCancel: undefined,
            onConfirm: handleConfirmDestroy,
            children: <ConfirmDeleteText />,
        });
    };

    return (
        <EditableGrid
            onSubmit={onSubmit}
            resolver={yupResolver(schema)}
            onDestroy={onDestroy}
            normalizeData={normalizeProperty}
            defaultValues={defaultValues}
            id={`ownedProperty-${property.id}`}
            editableKey={!readonly ? editableKey : undefined}
            context={{
                isIGTenant,
                type: application?.type,
                portPropertyId: application.portProperty?.id,
                propertyId: property?.id,
            }}
            gridGap={0}
            css={
                !noBorders &&
                css({
                    borderLeft: `6px solid ${applicant.applicantColor}`,
                    paddingLeft: 16,
                    margin: '10px 0',
                })
            }
        >
            <Titles
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Flex>{formatAddress(property.address)}</Flex>
                {!readonly && !isEditing && hasEditingRights && (
                    <Flex
                        data-testid="edit-owned-property"
                        css={{ cursor: 'pointer' }}
                        onClick={() => setEditingKey(editableKey)}
                    >
                        <Edit color="currentColor" size="14px" />
                    </Flex>
                )}
            </Titles>
            <Flex direction="column" gap={4}>
                <Sections
                    isEditing={isEditing}
                    applicationId={applicationId}
                    applicantId={applicant.applicantId}
                    property={property}
                    onToggle={onToggle}
                />
            </Flex>
        </EditableGrid>
    );
};
