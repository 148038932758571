import React from 'react';

import { Banner, Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import {
    FREQUENCY,
    IG_LIABILITY_PAY_OFF_OPTIONS,
    LIABILITY_PAYMENT_MULTIPLIER_OPTIONS,
    LIABILITY_PAY_OFF_OPTIONS,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { Liability } from 'types/applicant';
import { useIsTenant } from 'utils/useIsTenant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    liability: Liability;
};

export const Payment = ({ isCreating, isEditing, liability }: Props) => {
    const { i18n } = useI18n();

    const { showCreditMultiplier } = useFlags();

    const { watch } = useFormContext() || {};

    const { creditType, fromCreditBureau, payment, paymentMultiplier, payoff } =
        liability;

    let creditTypeWatch: Liability['creditType'] = creditType;

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    if (watch) {
        creditTypeWatch = watch('creditType');
    }

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('payments')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="payment.amount"
                    label="amount"
                    fieldType="money"
                    value={payment?.amount}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="payment.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={payment?.frequency}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />

                {showCreditMultiplier && (
                    <EditableCell
                        isEditing={isEditing}
                        name="paymentMultiplier"
                        label="paymentMultiplier"
                        fieldType="select"
                        options={LIABILITY_PAYMENT_MULTIPLIER_OPTIONS(i18n)}
                        value={paymentMultiplier}
                        isClearable
                        isFieldInvalidCompareFn={compareForId(liability)}
                    />
                )}

                <EditableCell
                    isEditing={isEditing}
                    name="payoff"
                    label="payoff"
                    fieldType="select"
                    options={
                        isIGTenant
                            ? IG_LIABILITY_PAY_OFF_OPTIONS(i18n)
                            : LIABILITY_PAY_OFF_OPTIONS(i18n)
                    }
                    value={payoff}
                    isClearable
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    isEditing={isEditing}
                    name="fromCreditBureau"
                    label="fromCreditBureau"
                    fieldType="readonly"
                    value={fromCreditBureau}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
            </Grid>
            <Banner visible={creditTypeWatch === 'REVOLVING'}>
                {i18n._('liabilities.revolvingMessage')}
            </Banner>
        </Flex>
    );
};
